export const allLineLinksData = {
    'en': [
        {'link': 'https://club4paws.ua/club4paws-plus-selection-adult-cats-chicken-veal-gravy'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-kotiv-shmatochki-z-krolikom-ta-indichkoiu-v-sousi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-kotiv-shmatochki-z-teliatinoiu-v-ovochevomu-zhele'},
        {'link': 'https://club4paws.ua/club4paws-plus-selection-adult-cats-atlantic-baltic-herring-jelly'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-kurkoiu-v-sousi'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-krolikom-v-sousi'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-ialovichinoiu-v-krem-supi-z-brokkoli'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-indichkoiu-v-krem-supi-z-morkvi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-sobak-malikh-porid-shmatochki-z-lososem-ta-makrelliu-v-sousi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-sobak-malikh-porid-shmatochki-z-ialovichinoiu-ta-ovochami-v-sousi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-sobak-malikh-porid-shmatochki-z-kachkoiu-ta-indichkoiu-v-sousi'},
        {'link': 'https://club4paws.ua/club4paws-selection-turkey-lamb-dog'},
        {'link': 'https://club4paws.ua/club4paws-selection-chicken-lamb-dog'},
        {'link': 'https://club4paws.ua/club4paws-selection-chicken-turkey-dog'},
        {'link': 'https://club4paws.ua/club4paws-selection-chicken-beef-dog'},
        {'link': 'atb'},
        {'link': 'https://club4paws.ua/club4paws-selection-adult-cats-duck-vegetables'},
        {'link': 'rozetka'},
        {'link': 'rozetka'},
    ],
    'ua': [
        {'link': 'https://club4paws.ua/club4paws-plus-selection-adult-cats-chicken-veal-gravy'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-kotiv-shmatochki-z-krolikom-ta-indichkoiu-v-sousi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-kotiv-shmatochki-z-teliatinoiu-v-ovochevomu-zhele'},
        {'link': 'https://club4paws.ua/club4paws-plus-selection-adult-cats-atlantic-baltic-herring-jelly'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-kurkoiu-v-sousi'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-krolikom-v-sousi'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-ialovichinoiu-v-krem-supi-z-brokkoli'},
        {'link': 'https://shop.club4paws.com/selection-vologii-korm-dlia-doroslikh-kotiv-smuzhki-z-indichkoiu-v-krem-supi-z-morkvi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-sobak-malikh-porid-shmatochki-z-lososem-ta-makrelliu-v-sousi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-sobak-malikh-porid-shmatochki-z-ialovichinoiu-ta-ovochami-v-sousi'},
        {'link': 'https://shop.club4paws.com/plus-selection-vologii-korm-dlia-doroslikh-sobak-malikh-porid-shmatochki-z-kachkoiu-ta-indichkoiu-v-sousi'},
        {'link': 'https://club4paws.ua/club4paws-selection-turkey-lamb-dog'},
        {'link': 'https://club4paws.ua/club4paws-selection-chicken-lamb-dog'},
        {'link': 'https://club4paws.ua/club4paws-selection-chicken-turkey-dog'},
        {'link': 'https://club4paws.ua/club4paws-selection-chicken-beef-dog'},
        {'link': 'atb'},
        {'link': 'https://club4paws.ua/club4paws-selection-adult-cats-duck-vegetables'},
        {'link': 'rozetka'},
        {'link': 'rozetka'},
    ],
    'pl': [
        {'link': 'https://allegro.pl/oferta/club-4-paws-kurczak-i-cielecina-w-sosie-12x80g-12650263565'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-krolik-i-indyk-w-sosie-12x80g-12653362968'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-cielecina-w-warzywnej-galarecie-12x80g-12653862459'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-sledz-i-szprotki-w-galarecie-12x80g-12653759629'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-delikatny-kurczak-w-sosie-12x85g-12653945589'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-delikatny-krolik-w-sosie-12x85g-12654059474'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-wolowina-w-zupie-krem-z-brokulu-12x85g-12654177534'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-indyk-w-zupie-krem-z-marchewki-12x85g-12654225286'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-losos-i-makrela-w-sosie-12x85g-12670011198'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-wolowina-z-warzywami-w-sosie-12x85g-12672121696'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-kaczka-i-indyk-w-sosie-12x85g-12672069905'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-pasztet-z-indykiem-i-jagniecina-400g-12672458832'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-pasztet-z-kurczakiem-i-jagniecina-400g-12672781370'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-pasztet-z-kurczakiem-i-indykiem-400g-12672698343'},
        {'link': 'https://allegro.pl/oferta/club-4-paws-pasztet-z-kurczakiem-i-wolowina-400g-12672935736'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
    ],
    'lt': [
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-plus-suaugusioms-katems?id=59762984'},
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-plus-suaugusioms-katems?id=59754069'},
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-plus-suaugusioms-katems?id=59764244'},
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-plus-suaugusioms-katems?id=59765089'},
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-suaugusioms-katems-juosteles?id=59767094'},
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-suaugusioms-katems-juosteles?id=59766309'},
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-suaugusioms-katems-juosteles?id=59767719'},
        {'link': 'https://pigu.lt/lt/konservai-katems/club-4-paws-premium-suaugusioms-katems-juosteles?id=59765609'},
        {'link': 'https://pigu.lt/lt/gyvunu-prekes/sunims/konservai-sunims/club-4-paws-premium-plus-suaugusiems-mazu?id=59768439'},
        {'link': 'https://pigu.lt/lt/gyvunu-prekes/sunims/konservai-sunims/club-4-paws-premium-plus-suaugusiems-mazu?id=59769059'},
        {'link': 'https://pigu.lt/lt/gyvunu-prekes/sunims/konservai-sunims/club-4-paws-premium-plus-suaugusiems-mazu?id=59769554'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
    ],
    'ro': [
        {'link': 'https://www.fourpaws.ro/hrana-umeda-pentru-pisici/club-4-paws-premium-plus-selection-hrana-umeda-pentru-pisici-bucati-de-pui-si-vitel-in-sos-12x80g.html'},
        {'link': 'https://www.fourpaws.ro/hrana-umeda-pentru-pisici/club-4-paws-premium-plus-selection-hrana-umeda-pentru-pisici-bucati-de-iepure-si-curcan-in-sos-12x80g.html'},
        {'link': 'https://www.fourpaws.ro/hrana-umeda-pentru-pisici/club-4-paws-premium-plus-selection-hrana-umeda-pentru-pisici-bucati-de-vitel-in-jeleu-de-legume-12x80g.html'},
        {'link': 'https://www.fourpaws.ro/hrana-umeda-pentru-pisici/club-4-paws-premium-plus-selection-hrana-umeda-pentru-pisici-bucati-de-hering-baltic-atlantic-in-jeleu-12x80g.html'},
        {'link': 'https://www.fourpaws.ro/produse-pisici/club-4-paws-premium-selection-hrana-umeda-pentru-pisici-stripsuri-de-pui-in-sos-12x85g.html'},
        {'link': 'https://www.fourpaws.ro/produse-pisici/club-4-paws-premium-selection-hrana-umeda-pentru-pisici-stripsuri-de-iepure-in-sos-12x85g.html'},
        {'link': 'https://www.fourpaws.ro/produse-pisici/club-4-paws-premium-selection-hrana-umeda-pentru-pisici-stripsuri-de-vita-in-supa-crema-de-broccoli-12x85g.html'},
        {'link': 'https://www.fourpaws.ro/produse-pisici/club-4-paws-premium-selection-hrana-umeda-pentru-pisici-stripsuri-de-curcan-in-supa-crema-de-morcovi-12x85g.html'},
        {'link': 'https://www.fourpaws.ro/hrana-umeda-pentru-caini/club-4-paws-premium-plus-selection-hrana-pentru-caini-adulti-de-talie-mica-bucati-de-somon-si-macrou-in-sos-12x85g.html'},
        {'link': 'https://www.fourpaws.ro/hrana-umeda-pentru-caini/club-4-paws-premium-plus-selection-hrana-pentru-caini-adulti-de-talie-mica-bucati-de-vita-si-legume-in-sos-12x85g.html'},
        {'link': 'https://www.fourpaws.ro/hrana-umeda-pentru-caini/club-4-paws-premium-plus-selection-hrana-pentru-caini-adulti-de-talie-mica-bucati-de-rata-si-curcan-in-sos-12x85g.html'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
    ],
    'md': [
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-slices-with-chicken--veal--s-kuricej-i-teljatinoj-v-souse-dlja-koshek--80-g'},
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-slices-with-rabbit--turkey--s-krolikom-i-indejkoj-v-souse-dlja-koshek--80-g'},
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-slices-with-veal--s-teljatinoj-v-'},
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-slices-with-atlantic-herring--baltic-herring--'},
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-strips-with-chicken--s-kuricej-v-souse-dlja-koshek--85-g'},
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-strips-with-rabbit--s-krolikom-v-souse-dlja-koshek--80-g'},
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-strips-with-beef--s-govjadinoj-v-'},
        {'link': 'https://www.zoofarm.md/dlja-koshek/paketikah/club-4-paws-selection-strips-with-turkey--s-indejkoj-v-'},
        {'link': 'https://www.zoofarm.md/dogs/v-paketikah/club-4-paws-selection-slices-with-salmon--mackrerel--s-lososem-i-makrelju-v-souse-dlja-sobak--85-g'},
        {'link': 'https://www.zoofarm.md/dogs/v-paketikah/club-4-paws-selection-slices-with-beef--veggies--s-govjadinoj-i-ovoshhami-v-souse-dlja-sobak--85-g'},
        {'link': 'https://www.zoofarm.md/dogs/v-paketikah/club-4-paws-selection-slices-duck--turkey--s-utkoj-i-indejkoj-v-souse-dlja-sobak--85-g'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
        {'link': 'cs'},
    ],
}
