import * as React from "react"
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql, useStaticQuery, withPrefix} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {useEffect, useState, useContext} from "react";
import RedButton from "./RedButton";
import {allLineLinksData} from "../all-line-data/allLineLinksData";

export default function ProductInfoModal({id}) {
    const { t } = useTranslation();
    const context = useContext(I18nextContext);
    const thisLineLinkData = context.language.toString() === 'en' ? allLineLinksData.en :
        context.language.toString() === 'pl' ? allLineLinksData.pl :
            context.language.toString() === 'lt' ? allLineLinksData.lt :
                context.language.toString() === 'ro' ? allLineLinksData.ro :
                    context.language.toString() === 'md' ? allLineLinksData.md :
                        allLineLinksData.ua;
    const { allProductInfoJson } = useStaticQuery(
        graphql`
            query {
              allProductInfoJson {
                nodes {
                  id
                  name
                  subName
                  title
                  weight
                  percentageOfMeat
                  ingredients
                  constituents
                  additivesPer
                  themeColor
                  recommendedDailyGuide {
                    items
                  }
                  metabolizableEnergy
                  otherFlavours
                  mainImg {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }
                  iconImg {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }
                  viewImg {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }                 
                }
              }
            }
          `
    );
    const [product, setProduct] = useState();
    const mainImg = product !== undefined && getImage(product.mainImg);
    const iconImg = product !== undefined && getImage(product.iconImg);
    const viewImg = product !== undefined && getImage(product.viewImg);

    useEffect(() => {
        setProduct(allProductInfoJson.nodes[id]);
    }, [id]);

    return (
        <div className="modal fade" id="product-info-modal" tabIndex="-1" aria-labelledby="productInfoModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="product-info modal-content">
                    <div className="close-icon position-absolute pointer d-flex align-items-center justify-content-center" data-bs-dismiss="modal">
                        <svg className="svg-icon">
                            <use xlinkHref={withPrefix('sprite.svg#close-icon')}></use>
                        </svg>
                    </div>
                    {product !== undefined &&
                        <div id="anchor-top" className="custom-scroll">
                            <div className="product-info__header d-flex justify-content-md-end p-3 pr-md-4 mr-xl-3" style={{backgroundColor: product.themeColor}}>
                                <h4 className="mb-0">{t(`${product.title}`)}</h4>
                            </div>
                            <div className="px-3 px-md-4 mx-xl-3">
                                <div className="product-info__banner">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="product-main-img-wrap position-relative mt-3 mt-md-0">
                                                <div className="product-main-img d-flex align-items-center justify-content-center">
                                                    <GatsbyImage image={mainImg} alt="food image"/>
                                                </div>
                                                <div className="product-weight">
                                                    {t(product.weight).split(',').map((weight, g) => {
                                                        return (
                                                            <span key={`weight-${g}`} className="d-inline-block me-2">{weight}</span>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="pr-md-4 mr-md-1 pr-xl-5 mr-xl-4">
                                                <div className="product-percent d-flex align-items-center mt-4 mt-md-3">
                                                    <div className="product-percent__img position-relative flex-shrink-0">
                                                        <GatsbyImage image={iconImg} alt="food icon"/>
                                                    </div>
                                                    <div className="product-percent__text position-relative w-100" dangerouslySetInnerHTML={{__html: t(product.percentageOfMeat)}}></div>
                                                </div>
                                                <div className="product-view d-flex align-items-center justify-content-center">
                                                    <GatsbyImage image={viewImg} alt="food view"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-btn d-block d-md-none text-center py-3 mt-3 bg-white">
                                        <RedButton modalBtn={true} linkTo={thisLineLinkData[id].link} btnText={t('toInternetShop')} />
                                    </div>
                                    <div className="mt-4 pt-2 mt-md-0 pt-md-0">
                                        <div className="product-description mb-4">
                                            <div className="product-description__cap mb-3">{t('ysIngredients')}</div>
                                            <div className="product-description__text">{t(product.ingredients)}</div>
                                        </div>
                                        <div className="product-description mb-4">
                                            <div className="product-description__cap mb-3">{t('ysAnalyticalConstituties')}</div>
                                            <div className="product-description__text">{t(product.constituents)}</div>
                                        </div>
                                        <div className="product-description mb-4">
                                            <div className="product-description__cap mb-3">{t('ysAdditivesPerOneKg')}</div>
                                            <div className="product-description__text">{t(product.additivesPer)}</div>
                                        </div>
                                        <div className="product-description mb-4">
                                            <div className="product-description__cap mb-3">{t('ysRecommendedDaily')}</div>
                                            <div className="product-description__text">
                                                <div className="d-flex mb-3">
                                                    {product.recommendedDailyGuide.map((column, i) => {
                                                        return (
                                                            <div key={`column-${i}`} className="w-50">
                                                                {column.items.map((item, j) => {
                                                                    return (
                                                                        <div key={`cell-${i}-${j}`} className="table-cell text-center">{j === 0 ? t(item) : item}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <ul className="pl-4 mb-3">
                                                    <li>{t('modalLi1')}</li>
                                                    <li>{t('modalLi2')}</li>
                                                    <li>{t('modalLi3')}</li>
                                                </ul>
                                                <div className="switching-block">
                                                    <h5 className="text-center text-xl-start mb-3">{t('SwitchingCap')}</h5>
                                                    <div className="row">
                                                        <div className="switching-block__item col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                                                            <strong className="pb-2">25%</strong>
                                                            <svg className="svg-icon mb-3">
                                                                <use xlinkHref={withPrefix('sprite.svg#plate-icon-1')}></use>
                                                            </svg>
                                                            <span>1 - 2 {t('dayWord')}</span>
                                                            <p className="mt-2 mb-0">{t('25percentageSwitch')}</p>
                                                        </div>
                                                        <div className="switching-block__item col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                                                            <strong className="pb-2">50%</strong>
                                                            <svg className="svg-icon mb-3">
                                                                <use xlinkHref={withPrefix('sprite.svg#plate-icon-2')}></use>
                                                            </svg>
                                                            <span>3 - 4 {t('dayWord')}</span>
                                                            <p className="mt-2 mb-0">{t('50percentageSwitch')}</p>
                                                        </div>
                                                        <div className="switching-block__item col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                                                            <strong className="pb-2">75%</strong>
                                                            <svg className="svg-icon mb-3">
                                                                <use xlinkHref={withPrefix('sprite.svg#plate-icon-3')}></use>
                                                            </svg>
                                                            <span>5 - 6 {t('dayWord')}</span>
                                                            <p className="mt-2 mb-0">{t('75percentageSwitch')}</p>
                                                        </div>
                                                        <div className="switching-block__item col-6 col-md-3 d-flex flex-column align-items-center mb-4">
                                                            <strong className="pb-2">100%</strong>
                                                            <svg className="svg-icon mb-3">
                                                                <use xlinkHref={withPrefix('sprite.svg#plate-icon-4')}></use>
                                                            </svg>
                                                            <span>7 - 8 {t('dayWord')}</span>
                                                            <p className="mt-2 mb-0">{t('100percentageSwitch')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-description mb-4">
                                            <div className="product-description__cap mb-3">{t('ysMetabolizable')}</div>
                                            <div className="product-description__text">{t(product.metabolizableEnergy)}</div>
                                        </div>
                                    </div>
                                    <div className="other-products mb-5 pb-5 mb-md-0 pb-md-0">
                                        <h4 className="mb-4"><strong>{t('otherFlavours')}</strong></h4>
                                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-start">
                                            {product.otherFlavours.map((item, i) => {
                                                const otherProduct = allProductInfoJson.nodes[item];
                                                const otherIconImg = getImage(otherProduct.iconImg);

                                                return (
                                                    <div key={`other-flavours-${i}`} className="choose-product__item d-flex flex-column align-items-center text-center pointer"
                                                         onClick={() => {
                                                             setProduct(allProductInfoJson.nodes[item]);
                                                             document.getElementById('anchor-top').scrollTo({top: 0, behavior: 'smooth'});
                                                         }}
                                                    >
                                                        <div className="text-center mb-3">
                                                            <GatsbyImage image={otherIconImg} alt={t(otherProduct.name)}/>
                                                        </div>
                                                        <strong className="text-uppercase">{t(otherProduct.name)}</strong>
                                                        <span className="text-uppercase">{t(otherProduct.subName)}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="modal-btn d-none d-md-block text-center py-4 mt-4 position-sticky bg-white">
                                        <RedButton modalBtn={true} linkTo={thisLineLinkData[id].link} btnText={t('toInternetShop')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
