import * as React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';
import {useEffect, useState, useContext} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import RedButton from "./RedButton";
import {tabsCategoryLinksData} from "../tabs-data/tabsCategoryLinksData";

export default function ProductsTabComponent({id, tabData, reverseView, selectedProduct}) {
    const { t } = useTranslation();
    const context = useContext(I18nextContext);
    const thisLineLinkData = context.language.toString() === 'en' ? tabsCategoryLinksData.en :
        context.language.toString() === 'pl' ? tabsCategoryLinksData.pl :
            context.language.toString() === 'lt' ? tabsCategoryLinksData.lt :
                context.language.toString() === 'ro' ? tabsCategoryLinksData.ro :
                    context.language.toString() === 'md' ? tabsCategoryLinksData.md :
                        tabsCategoryLinksData.ua;
    const [activeTab, setActiveTab] = useState(0);
    const [activeImage, setActiveImage] = useState(0);
    const animalImg = getImage(tabData.tabImg);
    const delay = 400;

    useEffect(() => {
        window.scrollBy(0,1);
    }, [activeTab])

    return (
        <section id={`tab-block-${id}`} className={`products-tab paddings ${reverseView ? 'reverse-tab' : ''}`}>
            <div className="container">
                <h2 className="position-relative text-center mb-4 pb-xl-3">
                    <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                        {t(tabData.tabTitle)}
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} duration={2} className="animal-img position-absolute d-none d-md-block" animateIn={reverseView ? 'fadeInRight' : 'fadeInLeft'}>
                        <GatsbyImage image={animalImg} alt="animal"/>
                    </ScrollAnimation>
                </h2>
                <div className="d-flex justify-content-center mb-4 pb-2 ">
                    <ScrollAnimation animateOnce={true} className="products-tab__nav-wrap nav position-relative d-inline-flex "
                                     id="myTab"
                                     role="tablist"
                                     animateIn="fadeInUp"
                                     delay={delay}
                    >
                        {tabData.tabContent.map((navTab, index) => {
                            return (
                                <div key={`nav-tab-${navTab.id}-${index}`}
                                     id={`nav-tab-${navTab.id}-${index}`}
                                     className={`products-tab__nav-item position-relative px-2 px-md-3 pb-2 pointer ${activeTab === index ? 'active' : 'btn-basic__flat'}`}
                                     data-bs-toggle="tab"
                                     data-bs-target={`#tab-content-${navTab.id}-${index}`}
                                     role="tab"
                                     aria-controls={`#tab-content-${navTab.id}-${index}`}
                                     aria-selected="true"
                                     onClick={() => setActiveTab(index)}
                                >
                                    {t(navTab.title)}
                                </div>
                            )
                        })}
                    </ScrollAnimation>
                </div>
                <div className="products-tab__content tab-content" id="myTabContent">
                    {tabData.tabContent.map((tab, index) => {
                        return (
                            <div key={`tab-content-${tab.id}-${index}`}
                                 id={`tab-content-${index}`}
                                 className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                 role="tabpanel"
                                 aria-labelledby={`nav-tab-${tab.id}-${index}`}
                            >
                                <div className="grid-container">
                                    <div className="selected-product-img position-relative flex-shrink-0 text-center mb-4 pb-2 mb-md-0 pb-md-0">
                                        {tab.items.map((item, i) => {
                                            let image = getImage(item.mainImg);

                                            return (
                                                <ScrollAnimation key={`food-img-${tab.id}-${index}-${i}`} animateOnce={true} className="position-absolute" animateIn={reverseView ? 'fadeInLeft' : 'fadeInRight'} delay={delay * 2}>
                                                    <GatsbyImage image={image} className={activeImage === i ? 'fade-in-img' : 'hidden-img'} alt="{food img}"/>
                                                </ScrollAnimation>
                                            )
                                        })}
                                    </div>
                                    <ScrollAnimation animateOnce={true} className="text-wrap" animateIn="fadeInUp" delay={delay}>
                                        <p className="mb-4 pb-2 mb-md-0 pb-md-0">{t(tab.description)}</p>
                                    </ScrollAnimation>
                                    <h4 className="d-md-none mb-4 text-center">{t('exploreFlavors')}</h4>
                                    <div className="choose-product">
                                        <div className={`d-flex ${tab.items.length === 4 ? 'justify-content-between' : 'justify-content-center justify-content-xl-start'} flex-wrap mt-md-4 pt-md-3 mt-xl-5 pt-xl-0`}>
                                            {tab.items.map((item, i) => {
                                                const foodIcon = getImage(item.img);

                                                return (
                                                    <ScrollAnimation key={`product-item-${index}-${i}`} className="w-sm-50" animateOnce={true} animateIn="fadeInUp" delay={(i + 1) * delay}>
                                                        <div className="choose-product__item d-flex flex-column align-items-center text-center pointer"
                                                             onMouseOver={() => setActiveImage(i)}
                                                             onClick={() => {
                                                                 setActiveImage(i);
                                                                 selectedProduct(item.id);
                                                             }}
                                                             data-bs-toggle="modal" data-bs-target="#product-info-modal"
                                                        >
                                                            <div className="text-center mb-3">
                                                                <GatsbyImage image={foodIcon} alt={t(item.title)}/>
                                                            </div>
                                                            <strong className="text-uppercase">{t(item.title)}</strong>
                                                            <span className="text-uppercase">{t(item.subTitle)}</span>
                                                        </div>
                                                    </ScrollAnimation>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={delay * 2} className="mt-5 pt-2 text-center">
                    <RedButton linkTo={thisLineLinkData[id].link}/>
                </ScrollAnimation>
            </div>
        </section>
    )
}
