import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ScrollAnimation from "react-animate-on-scroll";
import {Container} from "react-bootstrap";
import AnimatedHeaderComponent from "./AnimatedHeaderComponent";

export default function SelectFoodComponent() {
    const { t } = useTranslation();

    return (
        <section className="select-food paddings">
            <Container className="d-flex flex-wrap flex-xl-nowrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                <div className="select-food__item-1 order-2 order-xl-1 me-2 me-md-4 me-xl-0">
                    <ScrollAnimation animateIn="fadeInUp" duration={1.7} delay={400} animateOnce={true}>
                        <StaticImage
                            src="../images/select-food-Cat.png"
                            width={248}
                            quality={100}
                            formats={["png"]}
                            placeholder="blurred"
                            alt={t('selectFoodCatAlt')}
                            className="select-food__item-img mb-3"
                        />
                        <div className="select-food__item-title text-center">{t('selectFoodCatTitle')}</div>
                    </ScrollAnimation>
                </div>
                <div className="select-food__item-2 order-1 order-xl-2">
                    <h1 className="select-food__item-header text-center mb-2 pb-xl-1">{AnimatedHeaderComponent(t('selectFoodTitle'), 1)}</h1>
                    <ScrollAnimation animateIn="fadeInUp" duration={1.1}  delay={100} animateOnce={true}>
                        <div className="select-food__item-sub-header text-lowercase text-center mb-4 pb-xl-1">{t('selectFoodSubTitle')}</div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="zoomIn" duration={1.2}  delay={250} animateOnce={true}>
                        <div className="select-food__item-text mb-md-4 pb-md-3 mb-xl-0 pb-xl-0 d-none d-md-block">{t('selectFoodText')}</div>
                    </ScrollAnimation>
                </div>
                <div className="select-food__item-3 order-3 order-xl-3 ps-md-3 ps-xl-0">
                    <ScrollAnimation animateIn="fadeInUp" duration={1.7}  delay={400} animateOnce={true}>
                        <StaticImage
                            src="../images/select-food-Dog.png"
                            width={248}
                            quality={100}
                            formats={["png"]}
                            placeholder="blurred"
                            alt={t('selectFoodDogAlt')}
                            className="select-food__item-img mb-3"
                        />
                        <div className="select-food__item-title text-center">{t('selectFoodDogTitle')}</div>
                    </ScrollAnimation>
                </div>
                <div className="mt-3 pt-2 order-4 d-md-none">
                    <ScrollAnimation animateIn="zoomIn" duration={1.2} delay={350} animateOnce={true}>
                        <div className="select-food__item-text mb-md-4 pb-md-3 mb-xl-0 pb-xl-0">{t('selectFoodText')}</div>
                    </ScrollAnimation>
                </div>
            </Container>
        </section>
    )
}
