import React, {useContext, useState} from "react";

const Context = React.createContext();

export const useMobileForm = () => {
    return useContext(Context);
}

export const ContextProvider = ({ children }) => {
    const [isMobileFormOpen, setIsMobileFormOpen] = useState(false);

    const toggleMobileForm = (flag) => setIsMobileFormOpen(flag);

    return (
        <Context.Provider value={{
            visible: isMobileFormOpen,
            toggleMobileForm
        }}>
            {children}
        </Context.Provider>
    )
}