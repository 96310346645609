import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ScrollAnimation from "react-animate-on-scroll";
import {Container} from "react-bootstrap";

export default function AboutCompany() {
    const { t } = useTranslation();

    return (
        <section id="about" className="about-company paddings">
            <Container className="d-flex flex-wrap flex-xl-nowrap justify-content-xl-between">
                <div className="d-none d-md-block d-xl-none w-100">
                    <ScrollAnimation animateIn="slideInUp" duration={1} delay={5} animateOnce={true} >
                        <StaticImage
                            src="../images/logo_Kormotech.svg"
                            width={200}
                            quality={100}
                            formats={["auto", "webp", "avif"]}
                            placeholder="tracedSVG"
                            alt="Kormotech"
                            className="mb-3"
                        />
                        <h3 className="mb-4 pb-3">{t('aboutCompanySubTitle')}</h3>
                    </ScrollAnimation>
                </div>
                <div className="about-company__item pe-md-4">
                    <ScrollAnimation animateIn="slideInUp" duration={1} delay={5} animateOnce={true} >
                        <StaticImage
                            src="../images/logo_Kormotech.svg"
                            width={200}
                            quality={100}
                            formats={["auto", "webp", "avif"]}
                            placeholder="tracedSVG"
                            alt="Kormotech"
                            className="mb-3 d-md-none d-xl-inline-block"
                        />
                        <h3 className="mb-4 d-md-none d-xl-block">{t('aboutCompanySubTitle')}</h3>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" duration={1.05} delay={20} animateOnce={true} >
                        <p className="mb-4 pb-2 mb-md-0 pb-md-0">{t('aboutCompanyText')}</p>
                    </ScrollAnimation>
                </div>
                <div className="about-company__item d-flex flex-wrap ps-md-3">
                    <div className="w-50 mb-4 pb-xl-3 pe-2 pe-md-4">
                        <ScrollAnimation animateIn="zoomIn" duration={1.1} delay={20} animateOnce={true} >
                            <StaticImage
                                src="../images/ic-about_1.svg"
                                width={36}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                placeholder="tracedSVG"
                                alt={t('aboutCompanyIc1Text')}
                                className="mb-3"
                            />
                            <div>{t('aboutCompanyIc1Text')}</div>
                        </ScrollAnimation>
                    </div>
                    <div className="w-50 mb-4 pb-xl-3 ps-2 ps-md-3">
                        <ScrollAnimation animateIn="zoomIn" duration={1.15} delay={60} animateOnce={true} >
                            <StaticImage
                                src="../images/ic-about_2.svg"
                                width={36}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                placeholder="tracedSVG"
                                alt={t('aboutCompanyIc2Text')}
                                className="mb-3"
                            />
                            <div>{t('aboutCompanyIc2Text')}</div>
                        </ScrollAnimation>
                    </div>
                    <div className="w-50 pe-2 pe-md-3">
                        <ScrollAnimation animateIn="zoomIn" duration={1.1} delay={100} animateOnce={true} >
                            <StaticImage
                                src="../images/ic-about_3.svg"
                                width={36}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                placeholder="tracedSVG"
                                alt={t('aboutCompanyIc3Text')}
                                className="mb-3"
                            />
                            <div>{t('aboutCompanyIc3Text')}</div>
                        </ScrollAnimation>
                    </div>
                    <div className="w-50 ps-2 ps-md-3">
                        <ScrollAnimation animateIn="zoomIn" duration={1.15} delay={140} animateOnce={true} >
                            <StaticImage
                                src="../images/ic-about_4.svg"
                                width={36}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                placeholder="tracedSVG"
                                alt={t('aboutCompanyIc4Text')}
                                className="mb-3"
                            />
                            <div>{t('aboutCompanyIc4Text')}</div>
                        </ScrollAnimation>
                    </div>
                </div>
            </Container>
        </section>
    )
}
