import * as React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';
import ScrollAnimation from "react-animate-on-scroll";
import {Container} from "react-bootstrap";
import AnimatedHeaderComponent from "./AnimatedHeaderComponent";
import {useState, useContext} from "react";
import {graphql, useStaticQuery, withPrefix} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {allLineLinksData} from "../all-line-data/allLineLinksData";

export default function AllLine({selectedProduct}) {
    const { t } = useTranslation();
    const [isMoreBtn, setIsMoreBtn] = useState(true);
    const context = useContext(I18nextContext);
    const languageForAnchor = context.language.toString() === 'ua' ? '' : `/${context.language.toString()}`;
    const thisLineLinkData = context.language.toString() === 'en' ? allLineLinksData.en :
        context.language.toString() === 'pl' ? allLineLinksData.pl :
            context.language.toString() === 'lt' ? allLineLinksData.lt :
                context.language.toString() === 'ro' ? allLineLinksData.ro :
                    context.language.toString() === 'md' ? allLineLinksData.md :
                        allLineLinksData.ua;
    const { allAllLineProductsDataJson } = useStaticQuery(
        graphql`
            query {
              allAllLineProductsDataJson {
                nodes {
                  categoryName
                  id
                  categoryData {
                    id
                    name
                    weight
                    src {
                      childImageSharp {
                        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          `
    );

    return (
        <section id="all-line" className="all-line paddings">
            <Container>
                <h2 className="all-line-header text-center mb-4 pb-2 pb-xl-3">{AnimatedHeaderComponent(t('allLineH2'), )}</h2>
                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap">
                    <ScrollAnimation animateIn="zoomIn" duration={1} animateOnce={true} className="all-line__item-title mb-2 mb-xl-3 w-100 text-center d-md-none">
                        <div>{t('Wetfood')}</div>
                    </ScrollAnimation>
                    <span id="all-line-anchor" />
                    {allAllLineProductsDataJson.nodes.map((item, index, items) => {
                        return (
                            <div key={`all-line-key-${index}`}
                                 className={`all-line__item text-center ${!isMoreBtn ? 'group-products' : ''} ${index + 1 !== items.length ? 'me-md-3' : ''}`}
                            >
                                {index + 1 === items.length &&
                                    <ScrollAnimation animateIn="zoomIn" duration={index * 0.25} animateOnce={true} className="all-line__item-title mb-3 d-md-none">
                                        <div>{t('Dryfood')}</div>
                                    </ScrollAnimation>
                                }
                                <ScrollAnimation animateIn="zoomIn" duration={index * 0.25} animateOnce={true} className="all-line__item-title mb-2 mb-xl-3 d-none d-md-block">
                                    <div>{t(item.categoryName)}</div>
                                </ScrollAnimation>
                                {item.categoryData.map((item2, index2, items2) => {
                                    let image = getImage(item2.src);
                                    const weight = item2.weight > 999 ? `${item2.weight/1000} ${t('weightKg')}` :
                                        `${item2.weight} ${t('weightG')}`;

                                    return (
                                        <ScrollAnimation
                                            key={`all-line-item-${index2}`}
                                            animateIn="slideInUp"
                                            duration={index * 0.32}
                                            delay={index * 0.15}
                                            animateOnce={true}
                                            className={`${!isMoreBtn ? 'group-products__item' : ''} ${(index2 !== 0) && isMoreBtn ? 'd-none' : ''}`}
                                        >
                                            <div className="all-line__item-img d-flex justify-content-center align-items-end pointer mb-2"
                                                 data-bs-toggle="modal"
                                                 data-bs-target="#product-info-modal"
                                                 onClick={() => selectedProduct(item2.id)} >
                                                <GatsbyImage
                                                    image={image}
                                                    alt={`${t(item2.name)} ${t('club4PawsFooterAlt')}`}
                                                    className="all-line__item-img-inner"
                                                />
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center ${index2 + 1 !== items2.length ? 'mb-3' : ''}`}>
                                                {thisLineLinkData[item2.id].link === 'cs' ?
                                                    <div className="all-line__item-weight d-inline-block px-2 py-1">{t('comingSoon')}</div>
                                                    : thisLineLinkData[item2.id].link === 'atb' ?
                                                        <div className="all-line__item-weight d-inline-block px-2 py-1">{t('exclusivelyATB')}</div>
                                                    : thisLineLinkData[item2.id].link === 'rozetka' ?
                                                      <div className="all-line__item-weight d-inline-block px-2 py-1">{t('exclusivelyRozetka')}</div>
                                                    :
                                                    <>
                                                        <div className="all-line__item-weight d-inline-block px-2 py-1">{weight}</div>
                                                        <a href={thisLineLinkData[item2.id].link} className="ms-3" target="_blank">
                                                            <svg className="svg-icon">
                                                                <use xlinkHref={withPrefix('sprite.svg#cart-icon')}></use>
                                                            </svg>
                                                        </a>
                                                    </>
                                                }

                                            </div>
                                        </ScrollAnimation>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className="d-flex justify-content-center mt-4 pt-2 pt-xl-3">
                    <ScrollAnimation animateIn="slideInUp" duration={1.2} delay={300} animateOnce={true}>
                        <div className="btn-transparent px-4 py-2 border pointer position-relative" onClick={() => setIsMoreBtn(!isMoreBtn)}>
                            {isMoreBtn ?
                                t('allLineShowLine')
                                :
                                t('allLineHideLine')
                            }
                            <AnchorLink to={`${languageForAnchor}/#all-line-anchor`} className="position-absolute fake-link-block" />
                        </div>
                    </ScrollAnimation>
                </div>
            </Container>
        </section>
    )
}
