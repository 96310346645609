import * as React from "react"
import ScrollAnimation from "react-animate-on-scroll";

export default function AnimatedHeaderComponent(header, wrap) {
    const componentHeader = header.split(' ');

    return (
        <>
            {componentHeader.map((item, index, items) => {
                const duration = index * 0.27;

                return (
                    <div key={`comp-header-${index}`} className="d-inline-block">
                        <ScrollAnimation
                            animateIn="slideInUp"
                            duration={duration}
                            animateOnce={true}
                            className="d-inline-block" >
                            <div className={index + 1 !== items.length ? 'pe-2' : ''}>{item}</div>
                        </ScrollAnimation>
                        {index + 1 === wrap &&
                            <div className="d-block d-md-none" />
                        }
                    </div>
                )
            })}
        </>
    )
}
