import * as React from "react";
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';
import {withPrefix} from "gatsby";
import {Container, Row} from "react-bootstrap";
import AnimatedHeaderComponent from "./AnimatedHeaderComponent";
import {useContext} from "react";

export default function ContactFormComponent() {
    const { t } = useTranslation();
    const lang = useContext(I18nextContext).language.toString();
    const allWhereToBuyData = {
        'md': [
            {
                'alt': 'Linella',
                'link': ''
            },
            {
                'alt': 'Nr1',
                'link': ''
            },
            {
                'alt': 'Fidesco',
                'link': ''
            },
            {
                'alt': 'Green Hills',
                'link': ''
            }
        ]
    };
    const thisWhereToBuyData = lang === 'md' && allWhereToBuyData.md;

    return (
        lang === 'md' &&
            <section id="where-to-buy" className="d-inline-block w-100">
                <Container className="my-4 py-3 my-md-5 py-md-0 pt-xl-3 pb-xl-1">
                    <h2 className="all-line-header text-center mb-3">{AnimatedHeaderComponent(t('whereToBuy'), )}</h2>
                    <p className="all-line-header text-center mb-4 pb-2 pb-xl-3">{AnimatedHeaderComponent(t('whereToBuyText'), )}</p>
                    <Row>
                        {thisWhereToBuyData.map((item, index) => {
                            const svgImageName = `where-to-buy-${lang}-${index}`;

                            return (
                                <div key={`banners-key-${index}`} className="where-to-buy-item-wrapper col-12 col-md-6 col-xl-3 text-decoration-none position-relative">
                                    <div className={`where-to-buy-item where-to-buy-item-${index} mb-md-1`}>
                                        <svg>
                                            <use xlinkHref={withPrefix(`sprite.svg#${svgImageName}`)} />
                                        </svg>
                                    </div>
                                    {item.link !== '' &&
                                        <a href={item.link} className="fake-link-block text-decoration-none"
                                           rel="noreferrer noopener"
                                        />
                                    }
                                </div>
                            )
                        })}
                    </Row>
                </Container>
            </section>
    )
}
