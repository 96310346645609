import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner";
import SelectFoodComponent from "../components/SelectFoodComponent";
import {useTranslation} from "gatsby-plugin-react-i18next";
import AllLine from "../components/AllLine";
import ProductsTabComponent from "../components/ProductsTabComponent";
import AboutCompany from "../components/AboutCompany";
import {useState} from "react";
import ProductInfoModal from "../components/ProductInfoModal";
import WhereToBuy from "../components/WhereToBuy";
import {ContextProvider} from "../components/Context";
import VideoComponent from "../components/VideoComponent";

export default function IndexPage({data}) {
    const { t } = useTranslation();
    const [selectedId, setSelectedId] = useState(0);
    const productsTab = data.allProductsDataJson.nodes;

    return (
        <ContextProvider>
            <Layout>
                <Seo title={t('homeTitle')} description={t('homeDescription')} />
                <Banner />
                <VideoComponent />
                <SelectFoodComponent />
                {productsTab.map((tab, index) => {
                    return (
                        <ProductsTabComponent key={`tab-block-${index}`}
                                              id={index}
                                              tabData={tab}
                                              reverseView={index === 1 && true}
                                              selectedProduct={(data) => setSelectedId(data)}
                        />
                    )
                })}
                <AllLine selectedProduct={(data) => setSelectedId(data)} />
                <WhereToBuy />
                <AboutCompany />
                {/*<ContactFormComponent />*/}
                <ProductInfoModal id={selectedId}/>
            </Layout>
        </ContextProvider>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allProductsDataJson {
        nodes {
          id
          tabTitle
          tabImg {
            childImageSharp {
              gatsbyImageData(formats: WEBP, placeholder: BLURRED)
            }
          }
          linkTo
          tabContent {
            description
            id
            title
            items {
              id
              subTitle
              title
              img {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                }
              }
              mainImg {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
  }
`;
