import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';
import ScrollAnimation from "react-animate-on-scroll";
import  { ScrollRotate } from 'react-scroll-rotate';
import Slider from "react-slick";
import bgCat from "../images/banner/bg_Cat.png";
import bgDog from "../images/banner/bg_Dog.png"
import {useContext} from "react";

export default function Banner() {
    const { t } = useTranslation();
    const lang = useContext(I18nextContext).language.toString();
    const settings = {
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    autoplaySpeed: 4000,
                }
            },
            {
                breakpoint: 99999,
                settings: "unslick"
            }
        ]
    };

    return (
        lang === 'ua' ?
            <>
                <StaticImage
                    src="../images/banner/cats_vertical_768_web.png"
                    width={1025}
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    placeholder="none"
                    alt={t('logoAlt')}
                    className="banner-img w-100 h-auto d-md-none"
                />
                <StaticImage
                    src="../images/banner/cats_horizontal_1920_web.png"
                    width={1920}
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    placeholder="none"
                    alt={t('logoAlt')}
                    className="banner-img w-100 h-auto d-none d-md-block"
                />
            </>
            :
            <div className="banner position-relative">
                <div className="position-relative">
                    <div className="banner-cap position-absolute d-none d-md-block">
                        <ScrollAnimation animateIn="pulse" duration={3} className="infinite">
                            <StaticImage
                                src="../images/banner/Delickcious.png"
                                width={639}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                placeholder="none"
                                alt={t('logoAlt')}
                            />
                        </ScrollAnimation>
                    </div>
                    <Slider {...settings} className="banner-slider position-relative d-md-flex">
                        <div>
                            <div className="banner-slider__item bg-brown d-flex flex-column align-items-center justify-content-center" style={{backgroundImage: `url(${bgCat})`}}>
                                <div className="banner-slider__item-cap d-block d-md-none">
                                    <ScrollAnimation animateIn="pulse" duration={3} className="infinite">
                                        <StaticImage
                                            src="../images/banner/Delickcious.png"
                                            width={600}
                                            quality={100}
                                            formats={["auto", "webp", "avif"]}
                                            placeholder="none"
                                            alt={t('logoAlt')}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className="banner-slider__item-img anti-clockwise-anim">
                                    <ScrollRotate method={"perc"} from={0} to={45} loops={3}>
                                        <StaticImage
                                            src="../images/banner/Selection-for-Cat.png"
                                            width={510}
                                            quality={100}
                                            formats={["auto", "webp", "avif"]}
                                            placeholder="blurred"
                                            alt={t('bannerCatAlt')}
                                        />
                                    </ScrollRotate>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-slider__item bg-blue d-flex flex-column align-items-center justify-content-center" style={{backgroundImage: `url(${bgDog})`}}>
                                <div className="banner-slider__item-cap d-block d-md-none">
                                    <ScrollAnimation animateIn="pulse" duration={3} className="infinite">
                                        <StaticImage
                                            src="../images/banner/Delickcious.png"
                                            width={600}
                                            quality={100}
                                            formats={["png"]}
                                            placeholder="blurred"
                                            alt="Selection любов завжди вчасно"
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className="banner-slider__item-img clockwise-anim">
                                    <ScrollRotate method={"perc"} from={0} to={-45} loops={3}>
                                        <StaticImage
                                            src="../images/banner/Selection-for-Dog.png"
                                            width={510}
                                            quality={100}
                                            formats={["png"]}
                                            placeholder="blurred"
                                            alt="Selection любов завжди вчасно"
                                        />
                                    </ScrollRotate>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
    )
}
