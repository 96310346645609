import * as React from "react";
import {Container} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import {StaticImage} from "gatsby-plugin-image";
import {useContext} from "react";
import {I18nextContext} from "gatsby-plugin-react-i18next";

export default function VideoComponent() {
    const lang = useContext(I18nextContext).language.toString();

    return (
        lang === 'ua' &&
            <section className="video-component paddings">
                <Container>
                    <div className="video-corners position-relative p-2">
                        <div className="position-relative">
                            <div className="video-wrapper">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/IsR58-371yQ"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
    )
}
