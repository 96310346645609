export const tabsCategoryLinksData = {
    'en': [
        {'link': 'https://club4paws.ua/category/koti/goduvannia/selection'},
        {'link': 'https://club4paws.ua/category/koti/goduvannia/sukhi-kormi'},
        {'link': 'https://club4paws.ua/category/sobaki/goduvannia/selection'},
    ],
    'ua': [
        {'link': 'https://club4paws.ua/category/koti/goduvannia/selection'},
        {'link': 'https://club4paws.ua/category/koti/goduvannia/sukhi-kormi'},
        {'link': 'https://club4paws.ua/category/sobaki/goduvannia/selection'},
    ],
    'pl': [
        {'link': 'https://allegro.pl/uzytkownik/club4paws/karmy-mokra-karma-90041?tags%5B%5D=SHOP-TAG-80325624-fd8ff388-6bbd-45c9-9ffb-23f68de13470'},
        {'link': 'cs'},
        {'link': 'https://allegro.pl/uzytkownik/club4paws/karmy-mokra-karma-90062?tags%5B%5D=SHOP-TAG-80325624-fd8ff388-6bbd-45c9-9ffb-23f68de13470'},
    ],
    'lt': [
        {'link': 'https://pigu.lt/lt/parduotuve/uab-kormotech/prekes?filter[recommended][0]=is_new&c[10490]=10490&filter[attr_UHJla8SXcyDFvmVua2xhcw][0]=Q0xVQiA0IFBBV1M'},
        {'link': 'cs'},
        {'link': 'https://pigu.lt/lt/parduotuve/uab-kormotech/prekes?filter[recommended][0]=is_new&c[10373]=10373&filter[attr_UHJla8SXcyDFvmVua2xhcw][0]=Q0xVQiA0IFBBV1M'},
    ],
    'ro': [
        {'link': 'https://www.fourpaws.ro/produse?c=Club+4+Paws+Premium+Plus+Selection'},
        {'link': 'cs'},
        {'link': 'https://www.fourpaws.ro/produse?c=Club+4+Paws+Premium+Plus+Selection'},
    ],
    'md': [
        {'link': 'https://www.zoofarm.md/index.php?route=product/search&search=CLUB%204%20PAWS%20Selection&description=true&category_id=63&limit=25'},
        {'link': 'cs'},
        {'link': 'https://www.zoofarm.md/index.php?route=product/search&search=CLUB%204%20PAWS%20Selection&category_id=59&description=true'},
    ],
}
